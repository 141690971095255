import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCdDTSTHLP6Blk8IPi3Y7HqmNqoXa2mB_E",
  authDomain: "hangman-eee1a.firebaseapp.com",
  projectId: "hangman-eee1a",
  storageBucket: "hangman-eee1a.appspot.com",
  messagingSenderId: "997284326315",
  appId: "1:997284326315:web:a6512cdc94cfce6b1580ee",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export default db;
